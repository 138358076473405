/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    display: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 4s0-2 2-2h12s2 0 2 2v6s0 2-2 2h-4q0 1 .25 1.5H11a.5.5 0 010 1H5a.5.5 0 010-1h.75Q6 13 6 12H2s-2 0-2-2zm1.398-.855a.76.76 0 00-.254.302A1.5 1.5 0 001 4.01V10c0 .325.078.502.145.602q.105.156.302.254a1.5 1.5 0 00.538.143L2.01 11H14c.325 0 .502-.078.602-.145a.76.76 0 00.254-.302 1.5 1.5 0 00.143-.538L15 9.99V4c0-.325-.078-.502-.145-.602a.76.76 0 00-.302-.254A1.5 1.5 0 0013.99 3H2c-.325 0-.502.078-.602.145"/>',
    },
});
